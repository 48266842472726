<template>
  <b-modal
    id="modal-modify-second-add-other"
    title="Chọn gói dịch vụ thêm"
    title-class="text-airline font-medium-3 fw-700"
    body-class="px-75"
    centered
    size="lg"
    no-close-on-backdrop
    @show="handleShowModal"
    @hide="handleHideModal"
  >
    <template #modal-footer="{ close }">
      <div class="d-flex-center w-100">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill mr-2"
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="info"
          class="px-2 d-flex-center"
          pill
          @click="handleConfirmChange"
        >
          <!-- :disabled="isEmpty(getPaxListsToAdd)" -->
          <!-- <span class="align-middle">{{ $t('reservation.continue') }}</span> -->
          <div class="d-flex-center ml-25">
            <BSpinner
              v-if="loadingNextButton"
              small
              class="mx-2"
            />
            <span v-else>Tiếp tục</span>
          </div>
        </b-button>
      </div>
    </template>

    <b-overlay
      :show="isShow"
      rounded="sm"
      no-fade
      variant="white"
      spinner-variant="info"
      :opacity="0.9"
      no-center
    >
      <template #overlay>
        <div class="text-center py-2 mt-4">
          <p
            id="cancel-label"
            class="font-weight-bolder text-airline"
          >
            Đang tải dữ liệu ...
          </p>
          <div class="text-center">
            <b-spinner variant="info" />
          </div>
        </div>
      </template>

      <p class="fw-700 text-medium-1">
        Chọn gói dịch vụ mua thêm:
      </p>
      <b-card
        v-for="(segmentData, indexSegmentData) of arrDataHandle"
        :key="indexSegmentData"
        header-bg-variant="light-warning"
        header-class="py-50 px-1"
        body-class="pb-0 pt-50 px-75"
        class="border-warning shadow-lg"
      >
        <template #header>
          <div class="text-airline fw-700">
            <span class="font-small-4 mr-50">Hành trình: </span>
            {{ segmentData.segment.departure.iataCode }} - {{ segmentData.segment.arrival.iataCode }} ({{ convertISODateTime(segmentData.segment.departure.at, segmentData.segment.departure.timeZone).date }})
          </div>
        </template>

        <CardNamePax
          :arr-pax="segmentData.arrPax"
          :name-type="'dịch vụ'"
        />

        <b-card
          header-class="px-0 pt-50 pb-50 pb-md-0"
          body-class="px-0 pt-50 px-md-1 px-lg-2"
          class="mb-50"
        >
          <template #header>
            <div class="d-flex gap-1">
              <span class="text-body fw-700">Chọn gói dịch vụ: </span>
              <span v-if="['TH'].includes(bookingSource)">Chỉ được chọn 1 gói dịch vụ</span>
            </div>
          </template>

          <template v-if="!isEmpty(getOtherServiceBySegment(segmentData))">
            <b-row
              v-for="(otherItem, indexOther) of getOtherServiceBySegment(segmentData)"
              :key="indexOther"
              class="mb-1 mb-md-75 justify-content-center"
              no-gutters
            >
              <b-col
                cols="10"
                md="8"
                class="d-flex align-items-center"
              >
                <b-form-checkbox
                  :id="`baggageItem_${indexSegmentData}_${indexOther}`"
                  inline
                  :disabled="checkDisableItem(segmentData, otherItem)"
                  :checked="isChecked(segmentData.newAddItems, otherItem)"
                  @change="chooseItem(segmentData.newAddItems, otherItem)"
                >
                  <span :class="`font-weight-bolder ${['lg', 'xl'].includes(appBreakPoint) ? 'font-medium-1' : 'font-small-3'}`"> {{ resolveSsrNameByCode(otherItem.code) }}</span>
                  <span :class="`text-warning font-italic fw-700 font-medium-1 ${['lg', 'xl'].includes(appBreakPoint) ? 'font-medium-1' : 'font-small-3'}`">
                    {{
                      !isEmpty(otherItem) && otherItem.fares.find(f => ['ALL', 'ADULT', ''].includes(f.paxType))
                        ? formatCurrency(otherItem.fares.find(f => ['ALL', 'ADULT', ''].includes(f.paxType)).total)
                        : '-'
                    }}
                  </span>
                  <em v-if="bookingSource === 'F1' && otherItem?.ssrValue">
                    ({{ otherItem?.ssrValue }})
                  </em>
                </b-form-checkbox>
              </b-col>

              <b-col
                cols="2"
                md="4"
              >
                <div
                  v-if="isChecked(segmentData.newAddItems, otherItem)"
                  class="d-flex align-items-center justify-content-end"
                >
                  <div class="mr-50 d-none d-lg-inline">
                    Số lượng:
                  </div>
                  <div class="d-flex">
                    <b-form-input
                      v-remove-non-numeric-chars
                      value="1"
                      style="width: 40px;"
                      :maxlength="2"
                      :disabled="true"
                      size="sm"
                      class="mx-50 px-50 fw-700 text-center font-medium-3 rounded"
                    />
                  </div>
                </div>

                <div
                  v-else
                  class="d-flex align-items-center justify-content-end"
                >
                  <div class="mr-50 text-secondary d-none d-lg-inline">
                    Số lượng:
                  </div>
                  <div class="d-flex">
                    <b-form-input
                      :value="''"
                      style="width: 40px;"
                      size="sm"
                      :disabled="true"
                      class="mx-50 px-50 text-secondary text-center rounded"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else-if="isShow">
            <b-spinner
              class="m-1"
              variant="info"
            />
          </template>
          <template v-else>
            <span class="text-warning fw-700">
              Không có gói dịch vụ trên chặng này
            </span>
          </template>
        </b-card>
      </b-card>

      <ModalConfirmAddBaggage :arr-data-to-confirm="arrDataToConfirm" />
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BOverlay,
  BSpinner,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormInput,
} from 'bootstrap-vue'
import {
  ref, watchEffect,
} from '@vue/composition-api'
import {
  isEmpty, cloneDeep,
} from 'lodash'

import { resolveSsrNameByCode } from '@/constants/selectOptions'

import { convertISODateTime, formatCurrency } from '@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'
import useAncillaryServicesHandle from '@reservation/reservation-modify/components/detail/ancillary-services/useAncillaryServicesHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BOverlay,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,

    CardNamePax: () => import('../components/CardNamePax.vue'),
    ModalConfirmAddBaggage: () => import('./modal-third-confirm.vue'),
  },
  props: {
    dataListToSecond: {
      type: [Array, null],
      default: () => null,
    },
  },
  setup(props) {
    const { toastError, toastWarning } = useToast()
    const {
      getBookingData,
      fetchListAncillary,
    } = useReservationHandle()

    const {
      fnAncillary,
      bookingSource,
      delay,
    } = useAncillaryServicesHandle()

    const arrDataHandle = ref(null)
    const resOthers = ref(null)
    const arrDataToConfirm = ref(null)

    // ============================== lấy gói Others theo hành trình
    const getOtherServiceBySegment = segmentData => {
      if (resOthers.value) {
        const result = resOthers.value?.filter(sv => {
          if (['F1'].includes(segmentData.source)) return sv.flights === segmentData.TRIP_FLIGHT
          return (['VJ'].includes(segmentData.source)
            ? (sv.flights === segmentData.TRIP_FLIGHT)
            : (sv.flights === segmentData.flights)) && (sv.segmentIds[0] === segmentData.segment.segmentId)
        })
        return result
      }
      return []
    }

    // NOTE: Fetch and check overloading ====
    const isShow = ref(true)
    const errorServicesData = ref(null)
    const loadingNextButton = ref(false)

    function setTimeoutWithoutId(callback, delay) {
      return setTimeout(callback, delay)
    }

    watchEffect(() => {
      if (isShow.value && !isEmpty(resOthers.value) && !isEmpty(arrDataHandle.value)) {
        setTimeoutWithoutId(() => { isShow.value = false }, 700)
      }
    })

    function handleFetchListAncillary(data) {
      errorServicesData.value = null
      fetchListAncillary(data)
        .then(res => {
          if (isEmpty(res.ssrOthers)) {
            toastError('Không có gói dịch vụ khả dụng trên hành trình hiện tại!')
            errorServicesData.value = 'Không có gói dịch vụ khả dụng trên hành trình hiện tại!'
          } else {
            resOthers.value = res.ssrOthers.map(b => ({ ...b, quantity: 1 }))
          }
        })
        .catch(() => {
          errorServicesData.value = true
        })
        .finally(() => {
          isShow.value = false
        })
    }

    // ===========================
    function handleShowModal() {
      errorServicesData.value = null
      arrDataHandle.value = cloneDeep(props.dataListToSecond)
      handleFetchListAncillary(getBookingData.value)
    }
    function handleHideModal() {
      resOthers.value = null
      isShow.value = true
      arrDataHandle.value = false
    }

    // ======================= Check Other Services Item
    function isChecked(arr, otherItem) {
      return arr.some(i => (i.flights === otherItem.flights)
      && (i.code === otherItem.code)
      && (i.segmentIds[0] === otherItem.segmentIds[0]))
    }
    function setArray(array, item) {
      const index = array.findIndex(el => (el.flights === item.flights) && (el.code === item.code) && (el.segmentIds[0] === item.segmentIds[0]))
      if (index >= 0) array.splice(index, 1)
      else {
        array.push(item)
      }
      return array
    }
    function chooseItem(arr, item) {
      arr = setArray(arr, item)
    }
    async function handleConfirmChange() {
      const getAvailTripHasAddons = arrDataHandle.value.filter(item => !isEmpty(getOtherServiceBySegment(item)))
      const checkData = !isEmpty(getAvailTripHasAddons) && getAvailTripHasAddons.every(item => !isEmpty(item.newAddItems))

      if (!checkData) {
        toastWarning({
          title: 'Vui lòng chọn gói dịch vụ cho các hành trình!',
        })
        return
      }

      arrDataToConfirm.value = getAvailTripHasAddons.filter(item => !isEmpty(item.newAddItems))
      loadingNextButton.value = true
      try {
        await delay(300)
        this.$bvModal.show('modal-modify-third-confirm-add-other')
      } catch (error) {
        console.error('Error openModalBaggageSecond::', error)
      } finally {
        await delay(200)
        loadingNextButton.value = false
      }
    }

    function checkDisableItem(segmentData, otherItem) {
      // TH handle chỉ chọn 1 gói
      if (['TH'].includes(bookingSource.value)) {
        const hasSelectItem = segmentData.newAddItems.some(b => JSON.stringify(b) === JSON.stringify(otherItem))
        return !hasSelectItem
      }

      // QH mua 1 gói / chặng / người.
      if (['QH'].includes(bookingSource.value)) {
        const arrAddonsOfPax = segmentData.arrPax[0].addonsOfPax
        const existItem = arrAddonsOfPax.find(it => it.serviceCode === otherItem.code && it.segmentIds.some(s => otherItem.segmentIds.includes(s)))
        return !!existItem
      }

      return false
    }

    return {
      isShow,
      isEmpty,
      fnAncillary,
      bookingSource,
      getOtherServiceBySegment,
      resolveSsrNameByCode,
      convertISODateTime,

      arrDataHandle,
      resOthers,
      formatCurrency,
      handleConfirmChange,

      handleShowModal,
      handleHideModal,

      isChecked,
      chooseItem,
      loadingNextButton,
      arrDataToConfirm,
      checkDisableItem,
    }
  },
}
</script>
